<template>
    <div id="record">
        <ekk-fliter 
            placeholder="用户名/手机号"
            :noExport="true"
            :noStatus="true"
            :noDate="true"
            @keyword="filters.search"
        />
        <ekk-table 
            :tableData="viewData.tableData"
            :options="viewData.options"
            :page="viewData.page"
            :feedback="viewData.feedback"
            @handle="viewData.handle"
            @handlePage="viewData.handlePage"
        />
    </div>
</template>

<script>
import util from "@/utils/util";
import ekkTable from '@/components/ekk_table.vue'
import ekkFliter from '@/components/ekk_fliter.vue'

export default {
    name:'record',
    components: { 
        ekkTable,
        ekkFliter 
    },
    data(){
        return {
            keyword:'',
            filters:{
                // 关键字筛选
                search: val => {
                    setTimeout( ()=>{
                        [this.keyword,this.viewData.page.cur] = [val, 1];
                        this.getList();
                    },300)
                },
            },
            viewData: {
                options: [
                    {prop: "avatarUrl",label: '头像',width: 60,overflow: true,src:true},
                    {prop: "nickName",label: '微信昵称',width: 100,overflow: true,},
                    {prop: "phone",label: '手机号',width: 80,overflow: true,},
                    {prop: "shop_name",label: '门店',width: 100,overflow: true,},
                    {prop: "entered_at",label: '进店时间',width: 160,overflow: true,},
                    {prop: "leaved_at",label: '离店时间',width: 160,overflow: true,},
                     {prop: "count",label: '扫码次数',width: 100,overflow: true,},
                    
                ],
                tableData: [],
                page: { cur: 1, size: 20, total: 0, },
                feedback: "",
                // 列表操作
                handle: (row, type) => {
                    switch (type) {
                        case "detail":
                            break;

                        default:
                            break;
                    }
                },
                // 页码切换
                handlePage: (val) => {
                    this.viewData.page.cur = val;
                    this.getList();
                },
            },
        }
    },
    created(){
        this.getList();
    },
    methods:{
        //获取列表
        getList(){
            this.viewData.feedback = 'loading';
            this.viewData.tableData = [];

            this.$http.get('orders',{
                params:{
                    search:this.keyword,
                    page:this.viewData.page.cur,
                    pagesize:this.viewData.page.size,
                }
            }).then( res =>{
                let {lists,total} = res;
                let _listCopy = [];
                if(lists.length>0){
                    lists.forEach((item, index) => {
                        _listCopy.push({
                            ...item,
                            'avatarUrl':item.user.avatarUrl,
                            'nickName':item.user.nickName,
                            'phone':item.user.phone,
                            'created_at': util.getLocalTime({
                                data:util.getRequestTime(item.created_at) 
                            }),
                            'count':item.count,
                           'leaved_at': item.leaved_at !='0001-01-01T00:00:00Z'?util.getLocalTime({
                                data:util.getRequestTime(item.leaved_at) 
                            }):'',
                            'entered_at': item.entered_at !='0001-01-01T00:00:00Z'?util.getLocalTime({
                                data:util.getRequestTime(item.entered_at) 
                            }):'',
                            'shop_name':item.shop.shop_name
                        });
                    });
                    this.viewData.feedback = '';
                }else{
                    this.viewData.feedback = 'empty';
                }
                this.viewData.tableData = _listCopy;
                this.viewData.page.total = total;
            }).catch( err =>{
                this.viewData.feedback = 'error';
            })
        },
    }
}
</script>

<style lang="scss" scoped>

</style>